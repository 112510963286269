import styled from "@emotion/styled";
import { Typography } from "@mui/material";

const TextStyle = styled(Typography)`
    font-size: ${props=>props.fs? props.fs : "15px"};
    color: ${props => props.c? props.c : "#FFFFFF"};
    font-family: dogsregular;
    @media (max-width: 600px){
        font-size: ${props=>props.fs600? props.fs600 : ""};
    }
`
const AllImage = styled.img`
    width: 100%;
    height: auto;
    max-width: ${props=>props.mw? props.mw : ""};
    @media (max-width: 600px){
        max-width: ${props=>props.mw600? props.mw600 : ""};
    }
`
export {TextStyle, AllImage}