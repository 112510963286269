import styled from '@emotion/styled';
import { Container, Grid, Typography } from '@mui/material';
import React from 'react'
import { TextStyle } from '../../styling/style';
import Cards from '../../component/Cards';
import Card1 from '../../assets/card1.png';
import Card2 from '../../assets/card2.png';
import Card3 from '../../assets/card3.png';
import Card4 from '../../assets/card4.png';
import BottomImg from '../../assets/bottom.png';

const HeadingStyle = styled(Typography)`
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-family: dogsregular;
    @media (max-width: 599px){
       font-size: 40px;
    }
`
const BackDiv = styled.div`
    position: relative;
    z-index: 1;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(${BottomImg});
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: contain;
        z-index: 2;
    }

`;
const ContractGrid = styled(Grid)`
      background-color: #9ED93F;
    border-radius: 10px;
    border: 2px solid black;
    display: flex;
    justify-content: space-between;
    padding: 10px 20px;
    margin: 5% 0%;
    z-index: 1;
`

const Tokenomics = () => {
    return (
        <div>
            <BackDiv>
                <Container maxWidth='lg'>
                    <Grid container sx={{ padding: '8% 0px' }}>
                        <Grid item xs={12}>
                            <HeadingStyle variant='h3' data-aos='fade-up'>Tokenomics</HeadingStyle>
                        </Grid>
                        <ContractGrid item xs={12} data-aos='fade-up'>
                            <TextStyle>Contract Address: </TextStyle>
                            <TextStyle sx={{wordBreak:"break-all"}}>0x86bdC6172E3076D2aA7e2A9EC41133537e753d9C</TextStyle>
                        </ContractGrid>
                        <Grid container sx={{ position: 'relative', zIndex: '0' }}>
                            <Cards
                                disc='Total Supply'
                                text='1,000,000,000'
                                image={Card1}
                            />
                            <Cards
                                disc='Taxes'
                                text='0/0'
                                image={Card2}
                            />
                            <Cards
                                disc='Liquidity Locked'
                                text='BURNT'
                                image={Card3}
                            />
                            <Cards
                                disc='Renounce'
                                text='YES'
                                image={Card4}
                            />
                        </Grid>
                    </Grid>
                </Container>

            </BackDiv>
            <div style={{ padding: '5px 0px', backgroundColor: '#1B2111', zIndex: '3' }}>
                <TextStyle textAlign='center' fs600='12px'>© Copyright 2023, All Rights Reserved</TextStyle>
            </div>
        </div>
    )
}
export default Tokenomics;