import React from "react";
import Header from "../sections/1-Header";
import About from "../sections/2-About";

const Main = () => {
    return(
        <div style={{backgroundColor: 'black'}}>
            <Header/>
            <About/>
        </div>
    )
}
export default Main;