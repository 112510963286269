import React from "react";
import { AllImage, TextStyle } from "../../styling/style";
import styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";

const Cardstyle = styled(Grid)`
    background-color: #9ED93F;
    border-radius: 10px;
    border: 2px solid black;
    min-height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    flex-direction: column;
    margin: 30px 0px;
    @media (max-width: 599px){
        margin-top: 50px;
    }
`
const CardsTypo = styled(Typography)`
    color: black;
    text-align: center;
`
const ImageExd = styled(AllImage)`
    position: absolute;
    top: -29%;
    right: 38%;
    /* left: 0; */
`
const Cards = (props) => {
    const { disc, image ,text} = props;
    return(
        <Cardstyle xs={12} md={6}>
            <ImageExd mw='120px' src={image} data-aos='zoom-in'/>
            <CardsTypo variant="h4" data-aos="fade-up">{text}</CardsTypo>
            <CardsTypo data-aos="fade-up">{disc}</CardsTypo>
        </Cardstyle>
    )
}
export default Cards;