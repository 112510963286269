import styled from "@emotion/styled";
import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { AllImage, TextStyle } from "../../styling/style";
import AboutImage from '../../assets/aboutimg.png';
import Twitter from '../../assets/twitter.png';
import Telegram from '../../assets/telegram.png';
import EtherScan from '../../assets/etherscan.png';
import Dextool from '../../assets/dextool.png';
import Buy from "../3-Buy";
import Tokenomics from "../4-Tokenomics";

const CardGrid = styled(Grid)`
    background-color: #9ED93F;
    padding: 20px 20px 0px;
    border-radius: 20px;
    border: 2px solid black;
    @media (max-width: 599px){
        margin: 30px 0px;
    }
`
const ImageGrid = styled(Grid)`
    display: flex;
    @media (max-width: 599px){
        justify-content: center;
    }
    
`
const TextStyleExt = styled(TextStyle)`
    color: black;
    max-width: 600px;
    padding: 10px 0px;
    @media (max-width: 599px){
       font-size: 16px;
       text-align: center;
    }
`
const GridButton = styled(Grid)`
    text-align: center;
`

const BackgroundDiv = styled.div`
    background: radial-gradient(circle, rgba(153, 212, 59, 0.2), rgba(4, 7, 0, 1));
`
const About = () => {
    return (
        <BackgroundDiv>
            <Container maxWidth='lg' sx={{ padding: "10% 0px" }}>
                <CardGrid container>
                    <Grid item={12} md={8} data-aos='fade-up'>
                        <TextStyleExt fs='22px'>Explore the unique appeal of Kawaii Froge, a crypto token seamlessly
                            blending Kawaii aesthetics with a froggy twist. With an engaged community
                            and distinct branding, Kawaii Froge presents an interesting opportunity in the
                            evolving crypto landscape.
                        </TextStyleExt>
                        <Grid container>
                            <GridButton item xs={12} sm={2} data-aos='zoom-in'>
                                <Button href="https://etherscan.io/address/0x86bdC6172E3076D2aA7e2A9EC41133537e753d9C" target="blank"><AllImage src={EtherScan} /></Button>
                            </GridButton>
                            <GridButton data-aos='zoom-in' item xs={12} sm={2}>
                                <Button href="https://www.dextools.io/app/en/ether/pair-explorer/0x86bdC6172E3076D2aA7e2A9EC41133537e753d9C" target="blank"><AllImage src={Dextool} /></Button>
                            </GridButton>
                            <GridButton data-aos='zoom-in' item xs={12} sm={2}>
                                <Button href="https://twitter.com/kawaiifrogeVIP" target="blank"><AllImage src={Twitter} /></Button>
                            </GridButton>
                            <GridButton data-aos='zoom-in' item xs={12} sm={2}>
                                <Button href="https://t.me/kawaiifrogeVIP" target="blank"><AllImage src={Telegram} /></Button>
                            </GridButton>
                        </Grid>
                    </Grid>
                    <ImageGrid item xs={12} md={4} data-aos="fade-down"
     data-aos-easing="linear"
     data-aos-duration="1500">
                        <AllImage src={AboutImage} />
                    </ImageGrid>
                </CardGrid>
            </Container>
            <Buy />
            <Tokenomics />
        </BackgroundDiv>
    )
}
export default About;