import styled from "@emotion/styled";
import { Container, Grid } from "@mui/material";
import React, { useEffect } from "react";
import { AllImage, TextStyle } from "../../styling/style";
import Heading from '../../assets/header1.png';
import HeaderImg from '../../assets/bottom.png';
import HeaderBottom from '../../assets/headerbottom.png';
import HeaderFrog from '../../assets/headerfrog.png';
import AOS from "aos";
import "aos/dist/aos.css";

const BackgroundDiv = styled.div`
    background: linear-gradient(to top, #82B92A , #040700 );
`
const TextExt = styled(TextStyle)`
    position: absolute;
    bottom: 20%;
    right: 15%;
    @media (max-width: 599px){
        bottom: 0%;
        font-size: 14px;
        right: 0%;
    }
`
const HeadingGrid = styled(Grid)`
    position: relative;
    text-align: center;
    padding: 20px 0px 2px;
    @media (max-width: 700px){
        padding: 50px 0px 5px;
    }
`
const Div = styled.div`
    position: relative;
    min-height: 100vh;
    box-shadow: 0px 0px 40px 40px #000000;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url(${HeaderBottom}), url(${HeaderImg});
        background-position: bottom, bottom;
        background-repeat: no-repeat;
        background-size: 100%;
        z-index: 1; 
    }
`;

const FrogImge = styled(AllImage)`
    position: absolute;
    left: 53%;
    bottom: 0;
    transform: translateX(-50%);
    z-index: 0;
`;


const Header = () => {
    useEffect(() => {
        AOS.init({
          duration: 800,
          offset: 200,
        });
      }, []);
    
      useEffect(() => {
        AOS.refresh();
      });
    return (
        <BackgroundDiv>
            <Div>
                <div style={{ padding: '7px 0px', backgroundColor: '#1B2111' }}>
                    <TextStyle textAlign='center'  sx={{wordBreak:"break-all"}}>Contract addess: 0x86bdC6172E3076D2aA7e2A9EC41133537e753d9C</TextStyle>
                </div>
                <Container maxWidth='lg'>
                    <Grid container sx={{ padding: "5% 0px" }}>
                        <HeadingGrid item xs={12}>
                            <AllImage data-aos ='fade-up' mw='70%' mw600='100%' src={Heading} />
                            <TextExt data-aos='zoom-in'>Not financial advice</TextExt>
                        </HeadingGrid>
                        <Grid item xs={12}>
                            <FrogImge mw='30%' src={HeaderFrog} />
                        </Grid>
                    </Grid>
                </Container>
            </Div>
        </BackgroundDiv>
    )
}
export default Header;