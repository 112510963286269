import styled from "@emotion/styled";
import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { AllImage, TextStyle } from "../../styling/style";
import BuyImage from '../../assets/buyimage.png';
import Ether from '../../assets/ether.png';
import Bag from '../../assets/bag.png';
import UniSwap from '../../assets/uni.png';
import Frog from '../../assets/frog.png';

const BackgroundDiv = styled.div`
    background: radial-gradient(circle, rgba(153, 212, 59, 0.2), rgba(4, 7, 0, 1));
`
const HeadingStyle = styled(Typography)`
    color: white;
    text-align: center;
    text-transform: uppercase;
    font-family: dogsregular;
`
const BoxStyle = styled(Box)`
    background-color: black;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    max-width: 45px;
   
`
const GridStyle = styled(Grid)`
    background-color: #9ED93F;
    padding: 20px;
    border-radius: 20px;
    margin-bottom: 20px;
`
const Buy = () => {
    return (
        <div>
            <Container maxWidth='lg'>
                <Grid container sx={{ padding: '10% 0px' }}>
                    <Grid item xs={12}>
                        <HeadingStyle variant="h3" data-aos='fade-up'>How to buy</HeadingStyle>
                    </Grid>
                    <Grid item xs={12} textAlign='center'>
                        <AllImage mw='100px' src={BuyImage} />
                    </Grid>
                    <Grid container>
                        <GridStyle item xs={12}>
                            <Grid container>
                                <Grid item xs={12} md={1}>
                                    <BoxStyle data-aos='zoom-in'>
                                        <AllImage mw='40px' src={Bag} />
                                    </BoxStyle>
                                </Grid>
                                <Grid item xs={12} md={10} sx={{ padding: '0px 10px' }} data-aos='fade-up'>
                                    <TextStyle c='black' fs='18px'>Create A Wallet</TextStyle>
                                    <TextStyle c='black' fs='16px' fs600='14px'>Download the MetaMask extension or app, set up your wallet, and securely store your provided seed phrase.</TextStyle>
                                </Grid>
                            </Grid>
                        </GridStyle>
                        <GridStyle item xs={12}>
                            <Grid container>
                                <Grid item xs={12} md={1}>
                                    <BoxStyle data-aos='zoom-in'>
                                        <AllImage mw='20px' src={Ether} />
                                    </BoxStyle>
                                </Grid>
                                <Grid item xs={12} md={10} sx={{ padding: '0px 10px' }} data-aos='fade-up'>
                                    <TextStyle c='black' fs='18px'>Get Some ETH</TextStyle>
                                    <TextStyle c='black' fs='16px' fs600='14px'>Purchase Ethereum from a trusted exchange like Coinbase or Binance, and transfer it to your MetaMask wallet.</TextStyle>
                                </Grid>
                            </Grid>
                        </GridStyle>
                        <GridStyle item xs={12}>
                            <Grid container>
                                <Grid item xs={12} md={1}>
                                    <BoxStyle data-aos='zoom-in'>
                                        <AllImage mw='40px' src={UniSwap} />
                                    </BoxStyle>
                                </Grid>
                                <Grid item xs={12} md={10} sx={{ padding: '0px 10px' }} data-aos='fade-up'>
                                    <TextStyle c='black' fs='18px'>Go To Uniswap</TextStyle>
                                    <TextStyle c='black' fs='16px' fs600='14px'>Open the Uniswap interface on your browser and connect your MetaMask wallet by clicking the "Connect Wallet" button.</TextStyle>
                                </Grid>
                            </Grid>
                        </GridStyle>
                        <GridStyle item xs={12}>
                            <Grid container>
                                <Grid item xs={12} md={1}>
                                    <BoxStyle data-aos='zoom-in'>
                                        <AllImage mw='40px' src={Frog} />
                                    </BoxStyle>
                                </Grid>
                                <Grid item xs={12} md={10} sx={{ padding: '0px 10px' }} data-aos='fade-up'>
                                    <TextStyle c='black' fs='18px'>Swap ETH For $FROGE</TextStyle>
                                    <TextStyle c='black' fs='16px' fs600='14px'>Within Uniswap, select the ETH-to-$FROGE trading pair. Enter your desired swap amount, and confirm the transaction in your MetaMask wallet. Wait for the transaction to complete – congrats, you now own $FROGE!</TextStyle>
                                </Grid>
                            </Grid>
                        </GridStyle>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}
export default Buy;